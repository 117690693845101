/* ------------------------------- MATERIAL UI IMPORT -------------------------------- */
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';

/* ------------------------------- PROJECT IMPORT -------------------------------- */
import { useBankId } from 'src/hooks/use-bankID';
import { useForm } from 'react-hook-form';
import { BankIDPending } from './Pending';
import { useTranslation } from 'react-i18next';
import { GTM } from 'src/utils/gtm';
import { useEffect, useState } from 'react';
import { collectAndSaveAgreement, signAgreement } from 'src/api/autogiro';
import bankIDImg from '../../assets/images/BankID_logo.svg';
import bankIDImgWhite from '../../assets/images/BankID_logo_white.svg';
import { openBankId } from 'src/utils/bankID';
import { AutogiroRequest } from 'interfaces/invoice';
import { BankIDAuth } from 'interfaces/auth';
import QRCode, { QRCodeSVG } from 'qrcode.react';
import CryptoJS from 'crypto-js';

/* ------------------------------- SSN /PERSONAL NUMBER TYPE -------------------------------- */
type Inputs = {
  ssn: string;
};

/* ------------------------------- AUTOGIRO BANKID FORM PROPS -------------------------------- */
interface AutogiroBankIDFormProps extends AutogiroRequest {
  userNonVisibleData: string;
}

/* ------------------------------- AUTOGIRO BANKID FORM COMPONENT -------------------------------- */
const AutogiroBankIDForm = ({
  data,
  disabled,
  onSuccess,
}: {
  data: AutogiroBankIDFormProps;
  disabled: boolean;
  onSuccess: () => void;
}) => {
  /* ------------------------------- FORM STATE -------------------------------- */
  const {
    formState: { isSubmitting },
  } = useForm<Inputs>();

  /* ------------------------------- VARIABLES DECLARETION -------------------------------- */
  const { t } = useTranslation();
  const theme = useTheme();
  const [bankIDFailureMessage, setBankIDFailureMessage] = useState(false);
  const [pollError, setPollError] = useState<null | string>(null);
  const [qrData, setQrData] = useState<null | string>(null);
  const [secondsSinceOrderCreation, setSecondsSinceOrderCreation] = useState<number>(0);
  const [qrAuthCode, setQRAuthCode] = useState<null | string>(null);
  const [bankIDRes, setbankIDRes] = useState<null | BankIDAuth>(null);
  const [qrOpen, setQrOpen] = useState(false);
  const refreshTime = 30;

  /* ------------------------------- SIGN WITH BANKID FUNCTION -------------------------------- */
  const [state, dispatchBankID] = useBankId({
    pollLimit: 30,
    pollWait: 1000,
    onResolved: () => {
      onSuccess();
    },
    onPollRejection: err => {
      if (err.response?.status === 500) return;
      setPollError(t(`auth.bankID.status.${err.status}.${err.hintCode}`));
    },
    effects: {
      auth(ssn, eDispatch) {
        signAgreement({ ...data, isMobile: !ssn })
          .then(res => {
            eDispatch({ type: 'resolve_auth', reference: res.orderRef });
            if (!ssn) {
              openBankId({
                autoStartToken: res.autoStartToken,
              });
            } else {
              setQrOpen(true);
              setbankIDRes(res);
              setSecondsSinceOrderCreation(0);
            }
          })
          .catch(error => {
            eDispatch({ type: 'reject_auth', error });
            GTM.bankIDSubmit({ action: 'error', error: JSON.stringify(error) });
            setBankIDFailureMessage(true);
          });
      },
      poll(reference, eDispatch) {
        collectAndSaveAgreement({ ...data, orderRef: reference })
          .then(res => {
            if (res.status === 'complete') {
              eDispatch({ type: 'resolve_poll', progress: 'success', auth: true });
              GTM.bankIDSubmit({ action: 'success' });
            } else if (res.status === 'pending') {
              setPollError(null);
              eDispatch({ type: 'resolve_poll', progress: 'pending', status: res });
            } else {
              setQrOpen(false);
              eDispatch({ type: 'reject_poll', error: res });
              GTM.bankIDSubmit({ action: 'error', error: res.hintCode });
            }
          })
          .catch(error => {
            setQrOpen(false);
            eDispatch({ type: 'reject_poll', error });
            GTM.bankIDSubmit({ action: 'error', error: JSON.stringify(error) });
            setBankIDFailureMessage(true);
          });
      },
    },
  });

  useEffect(() => {
    let interval: NodeJS.Timeout | undefined;

    if (secondsSinceOrderCreation < refreshTime && bankIDRes) {
      interval = setInterval(() => {
        setSecondsSinceOrderCreation(prevSeconds => prevSeconds + 1);
      }, 1000);
    } else if (secondsSinceOrderCreation >= refreshTime) {
      setQrOpen(false);
      clearInterval(interval);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [secondsSinceOrderCreation, bankIDRes]);

  useEffect(() => {
    if (secondsSinceOrderCreation <= refreshTime && bankIDRes) {
      generateQRCode(bankIDRes);
    }
  }, [secondsSinceOrderCreation, bankIDRes]);

  const initiateBankID = () => {
    setQrData('Please wait we are generating the code.');
    dispatchBankID({ type: 'auth', ssn: data.personalNumber });
    GTM.bankIDOtherDeviceForAutogiro();
  };
  const generateQRCode = (res: BankIDAuth) => {
    const hmac = CryptoJS.HmacSHA256(secondsSinceOrderCreation.toString(), res.qrStartSecret);
    const qrAuthCode = hmac.toString(CryptoJS.enc.Hex);
    setQRAuthCode(qrAuthCode);
    const qrData = `bankid.${res.qrStartToken}.${secondsSinceOrderCreation}.${qrAuthCode}`;
    setQrData(qrData);
  };

  return (
    <>
      {/* ------------------------------- BANKID PENDING COMPONENT -------------------------------- */}
      <BankIDPending state={state}>
        {bankIDFailureMessage ? (
          <Alert severity="error" sx={{ mb: 2 }}>
            <AlertTitle>{t('activateAutogiroModal.signError.title')}</AlertTitle>
            {t('activateAutogiroModal.signError.message')}
          </Alert>
        ) : null}
        {pollError ? (
          <Alert severity="error" sx={{ mb: 2 }}>
            {pollError}
          </Alert>
        ) : null}
        <>
          {/* ------------------------------- SIGN WITH BANKID ON SAME DEVICE BUTTON  -------------------------------- */}
          <Button
            type="button"
            disableElevation
            disabled={disabled || isSubmitting}
            fullWidth
            size="large"
            variant="contained"
            color="primary"
            onClick={() => {
              dispatchBankID({ type: 'auth' });
              GTM.bankIDSameDeviceForAutogiro();
            }}
          >
            {t('activateAutogiroModal.signWithSameDevice')}
            <img src={theme.palette.mode === 'dark' ? bankIDImg : bankIDImgWhite} height={35} alt="bankID" />
          </Button>
          {/* ------------------------------- SIGN WITH BANKID ON OTHER DEVICE BUTTON  -------------------------------- */}
          <Box sx={{ mt: 1 }}>
            <Button
              disableElevation
              disabled={disabled || isSubmitting}
              fullWidth
              size="large"
              variant="contained"
              color="primary"
              type="button"
              onClick={() => {
                initiateBankID();
              }}
            >
              {t('activateAutogiroModal.signWithOtherDevice')}
              <img src={theme.palette.mode === 'dark' ? bankIDImg : bankIDImgWhite} height={35} alt="bankID" />
            </Button>
          </Box>
        </>
      </BankIDPending>

      {/* ------------------------------- QR CODE POPUP -------------------------------- */}
      <Dialog open={qrOpen} onClose={() => setQrOpen(false)} maxWidth="sm" fullWidth>
        <DialogTitle sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Typography>{t('activateAutogiroModal.scanQRCode')}</Typography>
        </DialogTitle>

        <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 3 }}>
          <Stack
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
            useFlexGap
            flexWrap="wrap"
            spacing={2}
            mb={2}
          >
            <Stack position="relative">
              <QRCodeSVG value={qrData ?? ''} size={200} />
            </Stack>

            <Stack mt={2}>
              <ol style={{ paddingLeft: '10px', margin: 0 }}>
                {[1, 2, 3, 4].map(num => (
                  <Typography
                    key={num}
                    variant="body1"
                    color={theme =>
                      theme.palette.mode === 'light' ? theme.palette.grey[600] : theme.palette.grey[200]
                    }
                    fontSize="13px"
                    marginBottom="10px"
                    textAlign="left"
                  >
                    <li>{t(`auth.bankID.qr_instructions.${num}`)}</li>
                  </Typography>
                ))}
              </ol>
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AutogiroBankIDForm;
