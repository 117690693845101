import { BankIDAuth, BankIDState } from 'interfaces/auth';
import { transport } from 'src/utils/transport';
import { getEndUserIp } from './auth';
import { AutogiroRequest } from 'interfaces/invoice';
const apiURL = import.meta.env.VITE_API_URL;

/* show the autogiro registration form and after pressing the signing with autogiro -->  Show The contract PDF with a  sign and after selecting 
that  sign --> A circular progress with a message "Please open your bank id to complete the sign in process" --> And after finally a message 
"Thanks for registration. It is environment friendly.". */

export const autogiroEndpoints = {
  sign: `${apiURL}/api/autogiro/signAgreement`,
  generate: `${apiURL}/api/autogiro/generateAgreement`,
  collectAndSaveAgreement: `${apiURL}/api/autogiro/collectAndSaveAgreement`,
};

export const generateAgreement = (args: AutogiroRequest): Promise<{ autogiroPdfBase64: string }> => {
  return transport.post(autogiroEndpoints.generate, args);
};

export const signAgreement = async (
  args: {
    userNonVisibleData: string;
    isMobile: boolean;
  } & AutogiroRequest
): Promise<BankIDAuth> => {
  const endUserIp: { ipAddress: string } = await getEndUserIp();
  return transport.post(autogiroEndpoints.sign, {
    ...args,
    ipAddress: endUserIp.ipAddress,
  });
};

export const collectAndSaveAgreement = (args: { orderRef: string } & AutogiroRequest): Promise<BankIDState> => {
  return transport.post(autogiroEndpoints.collectAndSaveAgreement, args);
};
